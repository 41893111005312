import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="accordion"
export default class extends Controller {
  static targets = ['item']

  connect() {
    this.collapsible = this.element.dataset.collapsible === 'true'
    this.setContentHeight()
  }

  setContentHeight() {
    const contentElements = this.element.querySelectorAll('[data-content]')
    contentElements.forEach(contentElement => {
      const article = contentElement.querySelector('article')
      const height = article.scrollHeight
      contentElement.style.setProperty('--accordion-content-height', `${height}px`)
    })
  }

  handleClick(event) {
    const button = event.currentTarget
    const itemTarget = button.parentElement.parentElement
    const currentState = itemTarget.dataset.state
    const nextState = currentState === 'open' ? 'closed' : 'open'

    this.toggleItem(itemTarget, currentState, nextState)

    if (this.collapsible) {
      this.closeAllItemsExcept(itemTarget)
    }
  }

  toggleItem(item, currentState, nextState) {
    item.dataset.state = nextState
    item.querySelectorAll(`[data-state=${currentState}]`).forEach(element => {
      element.dataset.state = nextState
      if (element.dataset.content) {
        if (nextState === 'closed') {
          this.closeContent(element)
        } else {
          this.openContent(element)
        }
      }
    })

    setTimeout(() => {
      item.scrollIntoView({ behavior: 'smooth' })
    }, 300)
  }

  closeAllItemsExcept(item) {
    this.itemTargets.forEach(itemTarget => {
      if (itemTarget.dataset.index !== item.dataset.index) {
        itemTarget.dataset.state = 'closed'
        itemTarget.querySelectorAll(`[data-state]`).forEach(element => {
          element.dataset.state = 'closed'
          if (element.dataset.content) {
            this.closeContent(element)
          }
        })
      }
    })
  }

  closeContent(content) {
    content.classList.add('h-0')
    content.classList.add('invisible')
    content.classList.remove('visible')
  }

  openContent(content) {
    content.classList.add('visible')
    content.classList.remove('invisible')
    content.classList.remove('h-0')
  }
}
