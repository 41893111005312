import { Controller } from '@hotwired/stimulus'
import { useTransition } from 'stimulus-use'

// Connects to data-controller="mobile-nav-toggler"
export default class extends Controller {
  static targets = ['trigger', 'menu']

  connect() {
    if (!this.menuTarget) return

    useTransition(this, {
      element: this.menuTarget,
      enterActive: 'transition ease-out duration-300',
      enterFrom: 'transform opacity-0 scale-95',
      enterTo: 'transform opacity-100 scale-100',
      leaveActive: 'transition ease-in duration-300',
      leaveFrom: 'transform opacity-100 scale-100',
      leaveTo: 'transform opacity-0 scale-95',
    })
  }

  toggle() {
    this.toggleTransition()
    const open = this.triggerTarget.getAttribute('aria-expanded') === 'true'
    this.triggerTarget.setAttribute('aria-expanded', open ? 'false' : 'true')
    this.triggerTarget.dataset.state = open ? 'closed' : 'open'
  }
}
