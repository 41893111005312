import Popover from '@stimulus-components/popover'

// Connects to data-controller="popover"
export default class extends Popover {
  connect() {
    super.connect()
    this.element.addEventListener('click', this.preventParentClick.bind(this))
  }

  disconnect() {
    super.disconnect()
    this.element.removeEventListener('click', this.preventParentClick.bind(this))
  }

  show(event) {
    super.show(event)
    this.toggleTargetState(event.target)
  }

  hide(event) {
    super.hide(event)
    this.toggleTargetState(event.target)
  }

  toggleTargetState(el) {
    const firstMenuItem = el.querySelector('[role="menuitem"]')
    const isExpanded = firstMenuItem.getAttribute('aria-expanded') === 'true'

    el.dataset.state = isExpanded ? 'closed' : 'open'
    firstMenuItem.setAttribute('aria-expanded', isExpanded ? 'false' : 'true')
  }

  preventParentClick(event) {
    const targetHasPopup = event.target.getAttribute('aria-haspopup') === 'true'
    if (targetHasPopup) {
      event.preventDefault()
    }
  }
}
