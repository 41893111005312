import { Controller } from '@hotwired/stimulus'
import { isAfter, isDate, isFuture, subYears } from 'date-fns'
import { isEqual } from 'date-fns/fp'

// Connects to data-controller="new-account-form"
export default class extends Controller {
  static targets = ['password', 'passwordConfirmation', 'plan', 'errors']
  static values = { submitDisable: String, submitEnable: String, validations: Object }

  connect() {
    this.form = this.element.querySelector('form')
    this.submittingModal = this.element.querySelector('#submitting-modal > div')
    this.submitButton = this.form.querySelector('button[type=submit]')

    this.form.dataset['action'] = 'turbo:submit-start->new-account-form#disableForm'
    this.form.addEventListener('turbo:submit-end', this.formSubmitted.bind(this))

    if (!this.hasSubmitDisableValue) {
      this.submitDisableValue = 'Processing...'
    }
  }

  selectPlan(event) {
    const input = event.currentTarget
    this.toggleCheckedPlan(input)
  }

  toggleCheckedPlan(input) {
    this.planTargets.forEach(el => {
      if (input.checked && el === input.parentElement.parentElement) {
        el.setAttribute('aria-checked', true)
      } else {
        el.removeAttribute('aria-checked')
      }
    })
  }

  validatePassword() {
    const password = this.passwordTarget
    const confirmation = this.passwordConfirmationTarget

    if (password.value.length < 8) {
      password.setCustomValidity(this.validationsValue.password.length)
    } else {
      password.setCustomValidity('')
    }

    if (password.value === confirmation.value) {
      confirmation.setCustomValidity('')
    } else {
      confirmation.setCustomValidity(this.validationsValue.password.confirmation_mismatch)
    }
  }

  validateFullName(event) {
    const input = event.currentTarget
    const pairs = input.value
      .trim()
      .split(' ')
      .map(name => name.trim())
    const isValid = pairs.length > 1 && pairs.every(pair => pair.length > 0)

    if (isValid) {
      input.setCustomValidity('')
    } else {
      input.setCustomValidity(this.validationsValue.full_name.incomplete)
    }
  }

  validateBirthday(event) {
    const input = event.currentTarget
    const date = new Date(input.value)
    if (!isDate(date)) {
      input.setCustomValidity(this.validationsValue.birthday.invalid)
      return
    }

    if (isFuture(date)) {
      input.setCustomValidity(this.validationsValue.birthday.past_required)
      return
    }

    const today = new Date()
    const eighteenYearsAgo = subYears(today, 18)
    if (isEqual(date, eighteenYearsAgo) || isAfter(date, eighteenYearsAgo)) {
      input.setCustomValidity(this.validationsValue.birthday.adult)
      return
    }

    input.setCustomValidity('')
  }

  validateCPFNumber(cpf) {
    if (cpf.length !== 11) {
      return false
    }

    const blacklisted = [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
    ]

    if (blacklisted.includes(cpf)) {
      return false
    }

    const calculateDigit = (cpf, factor) => {
      const sum = cpf
        .split('')
        .slice(0, factor - 1)
        .reduce((acc, digit, index) => acc + parseInt(digit) * (factor - index), 0)
      const remainder = (sum * 10) % 11
      return remainder === 10 || remainder === 11 ? 0 : remainder
    }

    const firstDigit = calculateDigit(cpf, 10)
    const secondDigit = calculateDigit(cpf, 11)

    return firstDigit === parseInt(cpf[9]) && secondDigit === parseInt(cpf[10])
  }

  validateCPF(event) {
    const input = event.currentTarget
    const cpf = input.value.replace(/\D/g, '')
    const isValid = this.validateCPFNumber(cpf)
    if (isValid) {
      input.setCustomValidity('')
    } else {
      input.setCustomValidity(this.validationsValue.cpf.invalid)
    }
  }

  disableForm() {
    this.submitButton.innerHTML = this.submitDisableValue
    this.submitButton.disabled = true
    this.submittingModal.classList.toggle('hidden')
  }

  async formSubmitted(event) {
    if (event.detail.success) {
      const json = await event.detail.fetchResponse.response.json()
      location.href = json.payment_url
    } else {
      this.errorsTarget.scrollIntoView({ behavior: 'smooth' })
      this.submitButton.innerHTML = this.submitEnableValue
      this.submitButton.disabled = false
      this.submittingModal.classList.toggle('hidden')
    }
  }
}
