import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="modal"
export default class extends Controller {
  static values = { canClose: Boolean, id: String }

  connect() {
    if (!this.hasIdValue) {
      this.idValue = 'modal-frame'
    }

    window.addEventListener('keydown', this.closeOnEscape.bind(this))
  }

  disconnect() {
    window.removeEventListener('keydown', this.closeOnEscape.bind(this))
  }

  closeOnEscape(event) {
    if (this.canCloseValue && event.key === 'Escape') {
      this.close(event)
    }
  }

  close(event) {
    if (this.canCloseValue) {
      event.preventDefault()
      const modal = document.getElementById(this.idValue)
      modal.innerHTML = ''
      modal.removeAttribute('src')
      modal.removeAttribute('complete')
    }
  }
}
